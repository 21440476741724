const user_scopes = {
    descriptions: {
        custom_data: 'Seus dados personalizados',
        email: 'Seu endereço de e-mail',
        phone: 'Seu número de telefone',
        profile: 'Seu nome, nome de usuário, avatar e outras informações pessoais',
        roles: 'Seus papéis',
        identities: 'Suas identidades sociais vinculadas',
        'urn:logto:scope:organizations': 'Suas informações organizacionais',
        'urn:logto:scope:organization_roles': 'Seus papéis na organização',
        address: 'Seu endereço',
    },
};
export default Object.freeze(user_scopes);
